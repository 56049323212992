import React from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import Layout from '../../../../components/layout';

function CheckoutSuccess() {
  return (
    <Layout>
      <Box mt={10}>
        <Box my={5}>
          <Typography align='center' variant='body1' gutterBottom>
            Thank you for your interest in partnering with #GMIS2021 which will be held​
          </Typography>
          <Typography align='center' variant='body1' gutterBottom>
            at the Dubai Exhibition Center alongside Expo 2020 on November 22-27, 2021.​
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            Your registration is under process.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            Due to COVID-19 capacity restrictions, all registrations will need to be verified ​ by the GMIS Organising
            Committee before approval.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            An email confirmation has been sent to you. If you do not see the email in your inbox, please check your
            spam folder as it may have found its way there in error. If you find it, please be sure to add our email
            address to your safe sender list as we will be sending you regular updates related to the programme agenda.
            If you did not receive an email from us, please contact us on{' '}
            {/* <Typography variant='body1' component='span' color='primary'> */}
            <Link href='mailto:partners@gmisummit.com'> partners@gmisummit.com.</Link>
            {/* <a href='tel:registration@gmisummit.com'>registration@gmisummit.com.</a> */}
            {/* <a href='mailto:registration@gmisummit.com.'>registration@gmisummit.com.</a> */}
            {/* </Typography> */}
          </Typography>
        </Box>
        <Box>
          <img width='100%' src='../../images/gmis-static-banner.png' />
        </Box>
      </Box>
    </Layout>
  );
}

export default CheckoutSuccess;
